import React from 'react'
import PropTypes from 'prop-types'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import clsx from 'clsx'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import BlogSingleCard from '@components/global/blog/SingleCard'

import * as cx from './BlogArchiveSlider.module.scss'

const BlogArchiveSlider = ({
  breadcrumbs,
  breadcrumbsClassName = '',
  title = 'Agent Image Blog',
  description = 'Learn the tips and tools you need to succeed at real estate marketing in the digital age.',
  posts,
}) => {
  return (
    <section id="blog-archive-slider" className={clsx('container', cx.wrapper)}>
      <Breadcrumbs
        wrapperClassName={clsx(cx.breadcrumbs, breadcrumbsClassName)}
        data={breadcrumbs}
      />
      <div className={clsx('row', cx.heading)}>
        <div className={clsx('col', cx.title)}>
          <h1 className="heading-2">{title}</h1>
        </div>
        <div className={clsx('col', cx.description)}>
          <p className="subtitle-5 neutral-gray">{description}</p>
        </div>
      </div>
      <Splide
        hasTrack={false}
        tag="div"
        options={{
          type: 'fade',
          rewind: false,
          arrows: false,
          pagination: true,
          perPage: 1,
          autoHeight: false,
          lazyLoad: 'sequential',
          autoplay: false,
          interval: 5000,
        }}
        className={cx.slides}
      >
        <SplideTrack>
          {posts.map((post, index) => {
            return (
              <SplideSlide key={post.node.id}>
                <BlogSingleCard
                  wrapperClassName=""
                  layout="default"
                  post={post.node}
                />
              </SplideSlide>
            )
          })}
        </SplideTrack>
      </Splide>
    </section>
  )
}

BlogArchiveSlider.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  breadcrumbsClassName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  posts: PropTypes.array,
}

export default BlogArchiveSlider
