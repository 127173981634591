import React from 'react'
import PropTypes from 'prop-types'
import { modifyImageKeys } from '@utils/modifyImageKeys'
import clsx from 'clsx'
import parse from 'html-react-parser'
import { truncateString } from '@utils/truncateString'
import ElementImageFile from '@components/global/element/ImageFile'

import * as cx from './BlogSingleCard.module.scss'

const BlogSingleCard = ({
  wrapperClassName = '',
  layout = 'default',
  post = {},
}) => {
  return (
    <div
      className={clsx(
        cx.wrapper,
        wrapperClassName,
        layout === 'reverse' && cx.alt
      )}
    >
      <div className="image-responsive">
        <canvas width={1128} height={560}></canvas>
        {post?.featuredImage !== undefined && (
          <ElementImageFile
            src={modifyImageKeys(post?.featuredImage?.node)}
            alt={post?.title}
            lazy={true}
          />
        )}
      </div>
      <div className={clsx(cx.content)}>
        <div className={`badge`}>
          {post?.primaryCategory?.node?.name
            ? post?.primaryCategory?.node?.name
            : 'Blog'}
        </div>
        <h2 className="subtitle-2">{truncateString(post?.title, 77)}</h2>
        <p className="default-body large line-clamp-4">
          {truncateString(parse(post?.excerpt), 157)}
        </p>
        <a href="#" className={clsx('primary-button', cx.button)}>
          Continue Reading
        </a>
      </div>
    </div>
  )
}

BlogSingleCard.propTypes = {
  wrapperClassName: PropTypes.string,
  layout: PropTypes.oneOf(['default', 'reverse']),
  post: PropTypes.object,
}

export default BlogSingleCard
