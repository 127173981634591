import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import BlogSingleCard from '@components/global/blog/SingleCard'

import * as cx from './BlogArchiveInfinite.module.scss'

import SvgArrowAlt from '@svg/arrowAlt.inline'

const BlogArchiveInfinite = ({
  title = 'Our Latest Blog Posts',
  subtitle = 'We’ve got all your real estate marketing questions covered',
  posts,
}) => {
  const postsPerPage = 3
  const [showposts, setShowposts] = useState(3)
  const loadMoreItems = (arrayLength) => {
    const numberItems =
      showposts + postsPerPage < arrayLength
        ? showposts + postsPerPage
        : arrayLength
    setShowposts(numberItems)
  }

  return (
    <section
      id="blog-archive-infinite"
      className={clsx('container', cx.wrapper)}
    >
      <div className={clsx(cx.heading)}>
        <p className="subtitle-7 neutral-gray">{subtitle}</p>
        <h2 className="heading-5">{title}</h2>
      </div>
      {posts.slice(0, showposts).map((post, index) => {
        return (
          <div
            key={post.node.id}
            className={clsx(cx.post, index === showposts - 1 && cx.last)}
          >
            <BlogSingleCard
              wrapperClassName=""
              layout={index % 2 ? 'default' : 'reverse'}
              post={post.node}
            />
          </div>
        )
      })}

      <div className={clsx(cx.readMore)}>
        {posts.length > showposts && (
          <button
            className="primary-button alt svg-rotate"
            onClick={() => loadMoreItems(posts.length)}
          >
            <span>Read More Posts</span>
            <SvgArrowAlt />
          </button>
        )}
      </div>
    </section>
  )
}

BlogArchiveInfinite.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  posts: PropTypes.array,
}

export default BlogArchiveInfinite
