import React, { useState } from 'react'
import clsx from 'clsx'
import { pageData, useBlogArchiveMetadata } from '@hooks/blog-archive-metadata'
import { getImage } from 'gatsby-plugin-image'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import BlogArchiveSlider from '@components/pages/blog/archive/slider'
import BlogArchiveInfinite from '@components/pages/blog/archive/infinite'
import BlogPostsV2 from '@components/global/blog/BlogPostsV2'
import BlogArchiveSearch from '@components/pages/blog/archive/search'
import BlogArchiveBestRealEstate from '@components/pages/blog/archive/best-real-estate'
import SocialConnectCards from '@components/global/cards/SocialConnectCards'

import * as cx from './BlogArchive.module.scss'

const PageBlogArchive = () => {
  const query = useBlogArchiveMetadata()

  return (
    <LayoutInnerPage>
      <Seo
        description={query.blog.seo.metaDesc}
        seo={query.blog.seo}
        title={query.blog.seo.title}
      />

      <BlogArchiveSlider
        breadcrumbs={pageData.breadcrumbs}
        title={pageData.title}
        description={pageData.description}
        posts={query.featured.edges}
      />

      <BlogArchiveInfinite posts={query.posts.edges} />
      <BlogPostsV2
        // wrapperClassName={clsx(cx.trendingPosts)}
        background="https://resources.agentimage.com/redesign/local/blog-trending-posts-bg-landing-page.jpg"
        title="Trending Posts"
        subtitle="Stay updated with our"
      />
      <BlogArchiveSearch placeholder="Let Us Know What You’re Looking For" />
      <BlogArchiveBestRealEstate
        title="The Best Real Estate Agent Websites for 2022"
        buttonLabel="View our top 50 websites"
        buttonUrl="/the-best-real-estate-agent-websites-for-2022/"
        featured={getImage(query.BestRealEstate)}
        logos={[
          getImage(query.RealTrends),
          getImage(query.Horizon),
          getImage(query.Vega),
          getImage(query.Hermes),
          getImage(query.Muse),
        ]}
      />
      <div className={clsx(cx.form)}>
        <LeadContactForm
          version="LCF01"
          background={null}
          modelOrBackground="NicoAlt"
          title="Get a Free Consultation"
          subtitle="We’d love to hear from you"
          submitLabel="I want a stunning website!"
          leadName="Blog LCF"
          layout="default"
        />
      </div>
      <SocialConnectCards
        // wrapperClassName={cx.social}
        heading={pageData.heading}
        subheading={pageData.subheading}
      />
    </LayoutInnerPage>
  )
}

export default PageBlogArchive
